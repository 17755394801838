import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'

import './assets/style/reset.css'
import './assets/style/common.css'
import './assets/style/font.less'

import './utils/rem'
import './utils/storage'
import './utils/dependencies'

Vue.prototype.$axios = axios
FastClick.attach(document.body)

Vue.use(Element);

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.name.indexOf('home') !== -1) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '注册公司,公司注册,公司注册代理,公司注册代办,公司起名,起名公司,十角兽')
    document.querySelector('meta[name="description"]').setAttribute('content', '海南十角兽网络科技有限公司是专业的注册公司代理及公司起名的企业,为广大创业者、中小型企业提供专业的注册代理服务.多年企业服务经验,专业化团队办理,值得您的信赖.')
    document.title = '注册公司_公司起名_公司注册代理-十角兽';
  } else if (to.name.indexOf('product') !== -1) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '新公司注册,代理注册公司,注册公司代办, 公司注册流程')
    document.querySelector('meta[name="description"]').setAttribute('content', '十角兽专注于为企业提供新公司注册,代理注册公司,注册公司代办等企业服务,积累多年注册代理服务经验,诚信可靠,快速高效.')
    document.title = '新公司注册_代理注册公司_注册公司代办';
  } else if (to.name.indexOf('partner') !== -1) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '注册公司代理,注册公司流程,代理公司注册,代办注册公司')
    document.querySelector('meta[name="description"]').setAttribute('content', '十角兽专业提供注册公司代理,代理公司注册,注册公司流程及费用,工商营业执照代理,财务代理等众多业务,找十角兽代办让创业变得更简单.')
    document.title = '注册公司代理_注册公司流程_代理公司注册';
  } else if (to.name.indexOf('news') !== -1) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '公司取名,公司起名字,公司取名大')
    document.querySelector('meta[name="description"]').setAttribute('content', '海南十角兽,是专业给公司取名大全的网站,擅长公司企业起名、有各行业取名经验,提供免费起名字参考,打造起名行业诚信口碑.')
    document.title = '公司取名_公司起名字_公司取名大全';
  } else if (to.name.indexOf('case') !== -1) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '公司测名,公司测名字,公司取名字')
    document.querySelector('meta[name="description"]').setAttribute('content', '十角兽公司取名网,专业取名网站,免费为广大创业老板提供公司起名,公司取名,公司测名,企业名称注册查询系统精准对接工商大数据,解决公司起名核名、商标查询、经营范围生成等新注册企业遇到的工商问题.')
    document.title = '公司测名_公司测名字_公司取名字';
  }
  next()
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
