import PortalLayout from '@/layouts/PortalLayout'
export default {
  path: '/',
  redirect: '/home',
  component: PortalLayout,
  children: [
    {
      path: 'home',
      name: 'home',
      component: () => import(/* webpackChunkName: "router" */ '@/views/Home'),
      meta: {
        title: '首页',
      },
    },
    {
      path: 'product',
      name: 'product',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/Product'),
      meta: {
        title: '产品中心',
      },
    },
    {
      path: 'news',
      name: 'news',
      component: () => import(/* webpackChunkName: "router" */ '@/views/News'),
      meta: {
        title: '资讯动态',
      },
    },
    {
      path: 'news-detail',
      name: 'NewsDetail',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/NewsDetail'),
      meta: {
        title: '动态详情',
      },
    },
    {
      path: 'case',
      name: 'case',
      component: () => import(/* webpackChunkName: "router" */ '@/views/Case'),
      meta: {
        title: '成功案例',
      },
    },
    {
      path: 'about',
      name: 'about',
      component: () => import(/* webpackChunkName: "router" */ '@/views/About'),
      meta: {
        title: '关于我们',
      },
    },
    {
      path: 'partner',
      name: 'partner',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/partner'),
      meta: {
        title: '招募合伙人',
      },
    },
  ],
}
