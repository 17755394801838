<template>
  <div class="side-container">
    <div class="side-wrapper">
      <a class="side-one" v-for="(item, index) in sideTools" :key="item.key" :href="item.link" @mouseenter="onMouseEnter($event, index)" @mouseleave="onMouseLeave">
        <div :class="item.icon"></div>
        <div class="title">{{ item.title }}</div>
        <div class="line" v-if="index === 0"></div>
      </a>
    </div>
    <div class="app" v-show="appIsShow" :style="{ '--top': currentTop }" @mouseenter="onShowEnter" @mouseleave="onMouseLeave">
      <svg-icon url="side-app" v-show="currentIndex === 0"></svg-icon>
      <svg-icon url="side-online" v-show="currentIndex === 1"></svg-icon>
      <svg-icon url="side-weixin" v-show="currentIndex === 2"></svg-icon>
      <!-- <div class="title">{{ currentTips }}</div> -->
    </div>
    <div v-show="isShow" class="side-return-top" @click="onScrollTop">
      <svg-icon icon-class="side-return-top" class-name="side-icon"  />
    </div>
    
  </div>
</template>

<script>
import { sideTools } from '@/config'
export default {
  name: 'Side',
  data() {
    return {
      timer: null,
      currentTips: null,
      currentIndex: null,
      currentTop: '-8px',
      isShow: false,
      appIsShow: false,
    }
  },
  computed: {
    sideTools() {
      return sideTools
    },
  },
  inject: ['getPosition', 'scrollTo'],
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      this.isShow = scrollTop >= 200
    },
    onScrollTop() {
      this.scrollTo({ y: 0 })
    },
    onMouseEnter(_, i) {
      clearTimeout(this.timer)
      this.timer = null
      this.appIsShow = true
      this.currentIndex = i
      const topMap = {
        0: '-8px',
        1: '45px',
        2: '100px',
      }
      const tipsMap = {
        0: '扫码体验产品',
        1: '扫码在线咨询',
        2: '关注我们',
      }
      this.currentTop = topMap[i]
      this.currentTips = tipsMap[i]
    },
    onMouseLeave() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.appIsShow = false
        this.currentIndex = null
        this.currentTop = '-8px'
        this.currentTips = null
      }, 500)
    },
    onShowEnter() {
      clearTimeout(this.timer)
      this.timer = null
      this.appIsShow = true
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
}
</script>

<style scoped lang="less">
.side-container {
  position: fixed;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  z-index: 14;
  .side-wrapper {
    box-sizing: border-box;
    width: 58px;
    max-width: 58px;
    background: #2871FF;
    border-radius: 30px;
    padding: 25px 0;
    // border: 2px solid #fff;

    .side-one {
      display: block;
      cursor: pointer;
      text-align: center;
      // &:not(:first-child) {
      //   padding-top: 16px;
      // }
      // &:hover {
      //   .title {
      //     color: #2761ff !important;
      //   }
      //   .side-icon {
      //     color: #2761ff;
      //   }
      // }
      .side-icon1 {
        width: 32px;
        height: 32px;
        margin: 0 auto;
        background-image: url('../../assets/images/2.0/side-wx-icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .side-icon2 {
        width: 32px;
        height: 32px;
        margin: 0 auto;
        background-image: url('../../assets/images/2.0/side-kf-icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .title {
        width: 30px;
        // white-space: nowrap;
        margin: 0 auto;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
      }
      .line{
        width: 40px;
        height: 1px;
        opacity: 0.5;
        border-top: 1px solid #FFFFFF;
        margin: 0 auto;
        margin-bottom: 18px;
        margin-top: 25px;
      }
    }
  }
  .app {
    position: fixed;
    width: 220px;
    height: 206px;
    // border-radius: 2px;
    right: 60px;
    top: var(--top);
    text-align: center;
    .title {
      position: absolute;
      bottom: 11px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 20px;
    }
  }
  .side-return-top {
    cursor: pointer;
    position: absolute;
    bottom: -90px;
    left: 50%;
    transform: translateX(-50%);
    width: 58px;
    height: 58px;
    background: #2871FF;
    border-radius: 29px;
    animation: slide-top 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    .side-icon{
      font-size: 20px;
    }
    &:hover {
      color: #2761ff;
    }
  }
  .applets,
  .gzh {
    width: 120px;
    height: 120px;
    position: absolute;
  }
}
@keyframes slide-top {
  0% {
    bottom: -300px;
    opacity: 0;
  }
  100% {
    bottom: -90px;
    opacity: 1;
  }
}
</style>
