<template>
 <div id="">
 	<div class="footer-container" v-if="!isMobile">
 	    <div class="footer m-auto">
 	      <div class="footer-l">
 	        <svg-icon :url="footerInfo.logo" class-name="logo" />
 	        <div class="footer-l-base" v-for="(item, index) in footerInfo.base" :key="index">
 	          <span class="footer-l-base-title">{{ item.key }}</span>
 	          <span class="footer-l-base-desc">{{ item.value }}</span>
 	        </div>
 	      </div>
 	      <div class="footer-r">
 	        <div class="footer-other-paths-one" v-for="(item, index) in footerInfo.imgs" :key="index">
 	          <svg-icon :url="item.src" />
 	          <div class="title center">{{ item.title }}</div>
 	        </div>
 	      </div>
 	    </div>
 		<!-- <div style="width: 1200px;height: 1px;background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 33%, #FFFFFF 72%, rgba(255, 255, 255, 0) 100%);
 	opacity: 0.23;margin: 36px auto 20px;"></div> -->
 		<div @click="toLink" class="banquan">琼 ICP 备 2021005135 号 海南十角兽网络科技有限公司 版权所有</div>
 	  </div>
 	  <div v-else class="m-footer-container">
 	    <div class="footer-l">
 	      <div class="m-footer-other-paths-one" v-for="(item, index) in footerInfo.imgs" :key="index">
 	        <svg-icon :url="item.src" class="way" />
 	        <div class="title center">{{ item.title }}</div>
 	      </div>
 	    </div>
 	    <div class="footer-r">
 	      <svg-icon :url="footerInfo.logo" class-name="logo" />
 	      <div class="address">广州市林和西路161号中泰国际广场B塔3706C</div>
 	      <span>电话：</span><span>400-631-9898</span>
 	    </div>
 	  </div>
	  <!-- <div v-if="isMobile" style="width: 100%;
	  height: 1px;
	  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 30%, #FFFFFF 71%, rgba(255, 255, 255, 0) 100%);
	  opacity: 0.23;"></div> -->
 	  <div v-if="isMobile" class="icp_box" @click="toLink" style="cursor:pointer;font-size: 1.25rem;;font-family: AlibabaPuHuiTiR;color: #FFFFFF;line-height: 22px;text-align: center;background-color: #182030;padding:20px 0 20px 0;"><i></i>琼 ICP 备 2021005135 号 海南十角兽网络科技有限公司 版权所有</div>
 </div>
</template>

<script>
import { footerInfo } from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'

export default {
  name: 'Footer',
  mixins: [checkIsMobile],
  computed: {
    footerInfo() {
      return footerInfo
    },
  },
  methods:{
	  toLink() {
		  window.location.href = 'https://beian.miit.gov.cn/'
	  }
  }
}
</script>

<style scoped lang="less">
.footer-container {
  height: 363px;
  background: linear-gradient(200deg, #023176 0%, #0A1265 18%, #060B4C 100%); 
  padding-top: 70px;
  box-sizing: border-box;
  .footer {
    width: 1024px;
    display: flex;
    margin-bottom: 52px;
    &-l {
      flex: 1;
      .logo {
        margin-bottom: 28px;
        max-width: 140px;
      }
      &-base {
        margin-bottom: 12px;
        &-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          opacity: 0.5;
        }
        &-desc {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          opacity: 0.5;
        }
      }
    }
    &-r {
      display: flex;
      img {
        max-width: 135px;
        margin-bottom: 26px;
      }
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.5;
      }
    }
  }
  .footer-other-paths-one:last-child {
    margin-left: 32px;
  }
  .banquan{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
    cursor: pointer;
  }
}
.m-footer-container {
  background-color: #182030;
  display: flex;
  padding: 88px 40px 20px;
  align-items: center;
  .footer-l {
    flex: 1;
    display: flex;
  }
  .footer-r {
    flex: 1;
    padding-left: 23px;
    .logo {
      max-width: 180px;
    }
    .address {
      margin-top: 20px;
      font-size: 20px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 1px;
    }
    span {
      font-size: 20px;
      font-family: AlibabaPuHuiTiR;
      color: rgba(255, 255, 255, 0.64);
      line-height: 32px;
      &:nth-child(2) {
        color: #ffffff;
      }
    }
  }
  .m-footer-other-paths-one {
    flex: 1;
    .way {
      width: 192px;
    }
    .title {
      font-size: 20px;
      font-family: AlibabaPuHuiTiR;
      color: rgba(255, 255, 255, 0.64);
      line-height: 27px;
    }
  }
}
.icp_box {
	position: relative;
	> i {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 1PX;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 30%, #FFFFFF 71%, rgba(255, 255, 255, 0) 100%);
	}
}
</style>
